
import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
export default function Trendingblog() {

    const [loading, setLoading] = useState(true);
  const [type, settype] = useState(false);
  const [Recentcourse, setRecentcourse] = useState([]);
    const getCardData = async () => {
    try {
        const res = await fetch(
          `https://manage.skillalfa.com/api/v1/homeblog`
        );
        const data = await res.json();
        console.log(data.footerblog)
        if (data.status) {
          setRecentcourse(data.homeblog)
          //console.log(card)
         
        } else {
          settype(true)
            console.log("no")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
        // Initially load data when the component mounts
        getCardData();
      }, []);


      function formatDate(inputDateStr) {
        const inputDate = new Date(inputDateStr);
        const months = [
          "January", "February", "March",
          "April", "May", "June",
          "July", "August", "September",
          "October", "November", "December"
        ];
        const year = inputDate.getFullYear();
        const month = inputDate.getMonth();
        const day = inputDate.getDate();
      
        return `${day} ${months[month]} ${year}`;
      }

  return (
    <>
    
    <div className="blogarea__2 sp_top_100 sp_bottom_100">
    <div className="container">
      <div className="row">
        <div className="col-xl-12" data-aos="fade-up">
          <div className="section__title__2 text-center teamarea__margin">
            <div className="section__small__title">
              <span>ALFA INSIGHTS</span>
            </div>
            <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
              <h2>Stay Ahead with Alfa Insights</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
      {

loading &&   

<center> <div className="spinner-border" role="status">
<span className="sr-only">Loading...</span>
</div></center>

}
{

!loading && 
<>
{Recentcourse.length > 0 ? (
    <>
        {Recentcourse.map((dt, index) => (

      
<div className="col-lg-6">
              <div className="blog__content__wraper__2" data-aos="fade-up">
                <div className="blogarae__img__2">  <Link to={`/blog/${dt.slug}`}>
                  <img
                    style={{ height: 300 }}
                    src={`https://manage.skillalfa.com/storage/blog/${dt.image}`}
                    alt="blog"
                  /></Link>
                  <div className="blogarea__date__2">
                  {formatDate(dt.created_at)}
                  </div>
                </div>
                <div className="blogarea__text__wraper__2">
                  <div className="blogarea__heading__2">
                    <h3>
                      <Link to={`/blog/${dt.slug}`}>
                       {dt.title}
                      </Link>
                    </h3>
                  </div>
                  <div className="blogarea__list__2">
                    <ul>
                      <li>
                        <Link to={`/blog/${dt.slug}`}>
                          <i className="icofont-business-man-alt-2" /> {dt.author}
                        </Link>
                      </li>

                      <li>
                        <Link to={`/blog/${dt.slug}`}>
                          <i className="icofont-eraser-alt" /> {dt.cn}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="blogarea__paragraph">
                    <p>
                      {dt.meta_description}
                    </p>
                  </div>
                  <div className="blogarea__button__2">
                    <Link to={`/blog/${dt.slug}`}>
                      READ MORE
                      <i className="icofont-double-right" />
                    </Link>
                    {/* <Link to="#">
                      <div className="blogarea__icon__2">
                        <i className="icofont-share" />
                        <i className="icofont-heart" />
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

        ))}
        </>
        ) : (

<> 
 <center><p>No blog  available!</p></center>
 </>
            )}
            </>
        }
        <div className="col-xl-12" data-aos="fade-up">
          <div className="blogarea__bottom__button">
            <Link className="default__button" to="/blog">
              MORE BLOG
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Outlet/>
    </>
  )
}
