import React, { useState, useEffect } from "react";
import Home from './components/Home'
import { Link,Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Ongoing() {
  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })
  return (
    <>
         <Helmet>
    <title>Ongiong Batch  || SKILLA ALFA INSTITUTE </title>
        <meta name="description" content="Join our weekend courses and hands-on practice sessions.


" />
    </Helmet>
    <div className="breadcrumbarea">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb__content__wraper" data-aos="fade-up">
              <div className="breadcrumb__title">
                <h2 className="heading">Ongoing batch</h2>
              </div>
              <div className="breadcrumb__inner">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Ongoing batch</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape__icon__2">
        
        <img
          className=" shape__icon__img shape__icon__img__2"
          src="/img/herobanner/herobanner__2.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__3"
          src="/img/herobanner/herobanner__3.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__4"
          src="/img/herobanner/herobanner__5.png"
          alt="photo"
        />
      </div>
    </div>
    {/* breadcrumbarea__section__end*/}
    {/* course__section__start   */}
    <Home></Home>
   
    {/* course__section__end   */}
    <Outlet/>
  </>
  
  )
}
