import React, { useState, useEffect } from "react";
import Home from './components/Home'
import { Link,Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";
import Mntr from "../components/Mntr";

export default function Courses() {

   
  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })
  return (
    <>
    <Helmet>
    <title>Courses  || SKILLA ALFA INSTITUTE </title>
        <meta name="description" content="Explore our practical courses and hands-on training.

" />
    </Helmet>
    <>
  <div className="breadcrumbarea">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper" data-aos="fade-up">
            <div className="breadcrumb__title">
              <h2 className="heading">Featured Courses</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> Featured Course</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
 
            
               
              <Home></Home>  
              {/* <div className="container" >
                 <h5>Our Comprehensive Courses</h5>
    <p>
      We offer meticulously designed courses that cater to various aspects of
      the digital and e-commerce realm:
    </p>
    <div style={{ marginLeft: 50 }}>
      <p>
        <b>1.</b>
        Amazon Wholesale Comprehensive Course - A deep dive into the intricacies
        of Amazon wholesale business, equipping students with the knowledge to
        thrive in the competitive marketplace.
      </p>
    </div>
    <div style={{ marginLeft: 50 }}>
      <p>
        <b>2.</b> Ebay Wholesale Comprehensive Course - Tailored to offer a
        holistic understanding of the Ebay platform, fostering the skills
        required to excel in wholesale business.
      </p>
    </div>
    <div style={{ marginLeft: 50 }}>
      <p>
        <b>3.</b>
        Shopify Local &amp; International E-commerce with Marketing Techniques
        Comprehensive Course - A course that serves as a gateway to mastering
        both local and international e-commerce platforms, with a focus on
        marketing techniques that drive success.
      </p>
    </div>
    <div style={{ marginLeft: 50 }}>
      <p>
        <b>4.</b>
        Graphic Designing and Video Editing Comprehensive Course - A creative
        hub that nurtures skills in graphic design and video editing, molding
        students into experts in the digital art domain.
      </p>
    </div>
    <br />
               </div>  */}
               <Mntr></Mntr>
                
             
</>

    <Outlet/>
    </>
  )
}
