import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
import Newsletter from "./Newsletter";


export default function Footer() {


  const [loading, setLoading] = useState(true);
  const [type, settype] = useState(false);
  const [Recentblog, setRecentblog] = useState([]);
  const [coursename, setcoursename] = useState([]);
    const getCardData = async () => {
    try {
        const res = await fetch(
          `https://manage.skillalfa.com/api/v1/footerblog`
        );
        const data = await res.json();
        console.log(data.footerblog)
        if (data.status) {
          setRecentblog(data.footerblog)
          setcoursename(data.coursename)
          //console.log(card)
         
        } else {
          settype(true)
            console.log("no")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
        // Initially load data when the component mounts
        getCardData();
      }, []);
   // console.log(card.id)
   function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();
  
    return `${day} ${months[month]} ${year}`;
  }
  
  return (
    <>
    {/* footer__section__start */}
    <div id="your-footer-id" className="footerarea">
      <div className="container">
        <div className="footerarea__newsletter__wraper">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="footerarea__text">
              <p>
          Subscribe to the<span><b  style={{ color:'#25a49d' }} > SKILL ALFA INSTITUTE  </b></span>Newsletter for Exclusive Updates! Stay ahead in the digital world with our insights and updates. Join us on this journey to excellence."
          </p>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
              data-aos="fade-up"
            >
              
<Newsletter></Newsletter>

            </div>
          </div>
        </div>
        <div className="footerarea__wrapper footerarea__wrapper__2">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-up"
            >
              <div className="footerarea__inner footerarea__about__us">
                <div className="footerarea__heading">
                  <h3>About us</h3>
                </div>
                <div className="footerarea__content">
                  <p>
                    SKILL ALFA INSTITUTE, located in Garden Town, Multan Cantt,
                    offers comprehensive e-commerce and digital skills training.
                    Join us to stay 'Always Ahead, Always Alfa' in the competitive
                    digital world
                  </p>
                </div>
                <div className="foter__bottom__text">
                  <div className="footer__bottom__icon">
                    <i className="icofont-clock-time" />
                  </div>
                  <div className="footer__bottom__content">
                    <h6>Opening Houres</h6>
                    <span>Mon- Sunday (12.00pm - 7.00pm)</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-2  col-md-6 col-sm-6"
              data-aos="fade-up"
            >
              <div className="footerarea__inner">
                <div className="footerarea__heading">
                  <h3>Essential Links</h3>
                </div>
                <div className="footerarea__list">
                  <ul>
                    <li>
                      <Link to="/about-us">About SKILL ALFA INSTITUTE</Link>
                    </li>
                    {/* <li>
                                        <Link to="#">Expert Mentors
  </Link>
                                    </li> */}
                    <li>
                      <Link to="/account-management">Account Management</Link>
                    </li>
                    <li>
                      <Link to="/courses">Course Catalog</Link>
                    </li>
                    <li>
                      <Link to="/donation">Donation</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                    <li>
                      <Link to="/term-conditions">Term  & Conditions</Link>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
              data-aos="fade-up"
            >
              <div className="footerarea__inner footerarea__padding__left">
                <div className="footerarea__heading">
                  <h3>Courses</h3>
                </div>
                {

loading && 

 <div className="spinner-border" role="status">
<span className="sr-only">Loading...</span>
</div>

}
{

!loading && 
                <div className="footerarea__list">
                  
                  {coursename.length > 0 ? (
                            
                     <ul>
                  {coursename.map((dt, index) => (
                    <li>
                      <Link to="/courses">{dt.Name}</Link>
                    </li>
                   ))}
                    </ul>
                   ) : (


                     
                 <div> <p>No course available!</p></div>
                    
                    )}
                 
                </div>
}
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-12"
              data-aos="fade-up"
            >
              {/* { !type && */}
 
              
              <div className="footerarea__right__wraper footerarea__inner">
                <div className="footerarea__heading">
                  <h3>Recent Post</h3>
                </div>
                {

loading && 

<center> <div className="spinner-border" role="status">
<span className="sr-only">Loading...</span>
</div></center>

}
{

!loading && 
                <div className="footerarea__right__list">
                {Recentblog.length > 0 ? (
                  <ul>
                
                  
                  {Recentblog.map((dt, index) => (
                  
                    <li key={index} >
                      <Link to="#">
                        <div className="footerarea__right__img">
                        <img style={{width:'60px'}} src={`https://manage.skillalfa.com/storage/blog/${dt.image}`} alt="blog" />
                        </div>
                        <div className="footerarea__right__content">
                          <span>{formatDate(dt.created_at)}</span>
                          <Link  to={`/blog/${dt.slug}`}><p  style={{ 
    fontSize: '14px' 
 }} >{dt.title}</p></Link>
                        </div>
                      </Link>
                    </li>
                   
                   ))}
                  </ul>
                ):(

<>
<p>No Recent Post available!</p>
</>

                )}
                </div>
                }

              </div>

              {/* } */}
            </div>
          </div>
        </div>
        <div className="footerarea__copyright__wrapper footerarea__copyright__wrapper__2">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="copyright__logo">
              <div className="headerarea__left__logo">
                <Link to="/">
                  <img
                  style={{ width: '250px',height:'90px' }}
                    src="/img/bdbdufdbudf.png"
                    alt="logo"
                  />
                </Link>
              </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="footerarea__copyright__content footerarea__copyright__content__2">
                {/* <p>Copyright © <span>2023</span> by edurock. All Rights Reserved.</p> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">
              <div className="footerarea__icon footerarea__icon__2">
                <ul>
                  <li>
                    <Link target="_blank" to="http://www.facebook.com/SkillAlfaInstitute">
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="http://www.instagram.com/SkillAlfaInstitute">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.youtube.com/@SkillAlfaInstitute">
                      <i className="icofont-youtube-play" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.tiktok.com/@SkillAlfaInstitute">
                     <img style={{     width:'19px'}} src="/img/tiktok.svg" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Outlet />
    {/* footer__section__end */}
  </>
  
  )
}
