import { Link,Outlet } from "react-router-dom";
import Trendingblog from "../components/Trendingblog";
import Trendingcourse from "../components/Trendingcourse";
import Quote from "../components/Quote";
import Newsletter from "../components/Newsletter";
import {Helmet} from "react-helmet";
import Mntr from "../components/Mntr";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export default function Home() {


  // const imageStyle = {
  //   width: '100%',
  //   height: '640px', // Set the fixed height
  //   verticalAlign: 'top',
  //   border: '0',
  // };
  return (
    
    <>
<Helmet>
<title>Home  || SKILL ALFA INSTITUTE </title>
        <meta name="description" content="Discover skills, education, and charity opportunities at SKILLA ALFA INSTITUTE
" />
    </Helmet>
    {/* herobannerarea__section__start */}
{/*     
    <div>
      <style>
        {`
          .carousel .control-next.control-arrow:before {
            content: '\\2192';
            border-left: 0px solid #fff;
            text-shadow: 0px 0px 5px black;
             font-size: 80px;
          }
          .carousel .control-prev.control-arrow:before {
            content: '\\2190';
            border-right: 0px solid #fff;
            text-shadow: 0px 0px 5px black;
             font-size: 80px;
        }
        `}
      </style>
    <Carousel
      showThumbs={false}
      showArrows={true}
      autoPlay={false}
      infiniteLoop={true}
      
    >
    
      <div>
        <img  style={{ height:'660px' }} src="/img/alid/1.png"  alt="Image B" />
      </div>
      <div>
        <img style={{ height:'660px' }} src="/img/alid/2.png"  alt="Image B" />
      </div>
      <div>
       <video className="vid" controls width="100%" autoPlay muted  >
        <source src="/img/work.mp4" type="video/mp4" />
        
      </video>     </div> 
    </Carousel>
</div> */}
    <div className="herobannerarea herobannerarea__2">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
            data-aos="fade-up"
          >
            <div className="herobannerarea__content__wraper">
              <div className="herobannerarea__title">
                <div className="herobannerarea__small__title">
                  <span>SKILL ALFA INSTITUTE</span>
                </div>
                <div className="herobannerarea__title__heading__2 herobannerarea__title__heading__3">
                  <h2>Your Gateway to Digital Mastery </h2>
                </div>
              </div>
              <div className="herobannerarea__text herobannerarea__text__2">
                <p>
                  Invest in a future-ready education system, trusted by thousands.
                  Skill Alfa is more than a learning space; it's your launchpad to
                  a successful digital career. Join us, be Always Ahead, Always
                  Alfa.
                </p>
              </div>
             
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
            data-aos="fade-up"
          >
            <div  className="aboutarea__img__2" data-tilt="">
              <img
                className="aboutimg__1"
                src="/img/homepage3.png"
                alt="aboutimg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="herobannerarea__icon__2">
      
        <img
          className="herobanner__common__img herobanner__img__2"
          src="/img/herobanner/herobanner__2.png"
          alt="photo"
        />
        <img
          className="herobanner__common__img herobanner__img__3"
          src="/img/herobanner/herobanner__3.png"
          alt="photo"
        />
        
        <img
          className="herobanner__common__img herobanner__img__5"
          src="/img/herobanner/herobanner__5.png"
          alt="photo"
        />
      </div>
    </div>
   

<Quote></Quote>
    <>
  <br />
  <br />
  <div className="brandarea__2">
    <div className="container">
      <div className="row">
        <div
          className="brandarea__wraper brandarea__wraper__2"
          data-aos="fade-up"
        >
          <div className="brandarea__img">
            <a href="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/amazon.png"
                alt="brand"
              />
            </a>
          </div>
          <div className="brandarea__img">
            <a href="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/ebay.png"
                alt="brand"
              />
            </a>
          </div>
          <div className="brandarea__img">
            <a href="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/etsy.png"
                alt="brand"
              />
            </a>
          </div>
          <div className="brandarea__img">
            <a href="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/freelancer.png"
                alt="brand"
              />
            </a>
          </div>
          <div className="brandarea__img">
            <a href="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/shopify.png"
                alt="brand"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* brand__section__end */}
</>
<>
<Trendingcourse></Trendingcourse>
  <br />
  <br />
  <>
  {/* aboutarea__2__section__start */}
  <div className="aboutarea__2 aboutarea__4 sp_bottom_30">
    <div className="container">
      <div className="row">
        <div
          className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          data-aos="fade-up"
        >
          <div className="about__right__wraper__2 about__right__wraper__4">
            <div className="educationarea__img" data-tilt="">
              {/* <img
                className="aboutimg__4__img__1"
                src="/img/about/about_13.png"
                alt="about"
              /> */}
              <img
                className="aboutimg__4__img__2" style={{ width:'100%' }}
                src="/img/iStock-483897106-[Converted].png"
                alt="about"
              />
            </div>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          data-aos="fade-up"
        >
          <div className="aboutarea__content__wraper">
            <div className="section__title__2">
              <div className="section__small__title">
                <span>SKILL ALFA INSTITUTE</span>
              </div>
              <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                <h2>EDUCATION SOLUTION</h2>
              </div>
            </div>
            <div className="aboutarea__para aboutarea__para__2">
              <p>
              Discover SKILL ALFA INSTIUTE – Your Hub for Mastering Digital Skills and E-Commerce Excellence. Join us on a transformative learning journe
            </p>
            </div>
            <div className="aboutarea__list__2">
              <ul>
                <li>
                  <i className="icofont-check" /> Delve into Amazon &amp; eBay
                  wholesale strategies
                </li>
                <li>
                  <i className="icofont-check" />
                  Master Shopify's local &amp; international facets
                </li>
                <li>
                  <i className="icofont-check" /> Ignite your creativity with
                  graphic design &amp; video editing
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* aboutarea__2__section__end */}
</>


</>

<>
  {/* team__section__start */}

  {/* team__section__end*/}
</>
<Mntr></Mntr>
<>
  {/* blog__section__start */}
 <Trendingblog></Trendingblog>
      
       
  {/* blog__section__end */}
</>

<Outlet></Outlet>
  </>
  
  )
}
