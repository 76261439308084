import React, { useState, useEffect } from "react";
import { useParams,useNavigate  } from 'react-router-dom';
import Applyform from "../../components/Applyform";
import { Link,Outlet } from "react-router-dom";



export default function Applybatch() {

  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })
         
  const { '*': remainingPath } = useParams();
  const parts = remainingPath.split('/');
  const slug = parts[0];
  const [loading, setLoading] = useState(true);
  const [type, settype] = useState(false);
  const [Recentblog, setRecentblog] = useState([]);
    const getCardData = async () => {
    try {
        const res = await fetch(
          `https://manage.skillalfa.com/api/v1/batch/${slug}`
        );
        const data = await res.json();
        console.log(data.footerblog)
        if (data.status) {
          setRecentblog(data.batchsingle)
          //console.log(card)
         
        } else {
          settype(true)
            console.log("no")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false)
      }
    }
    console.log(Recentblog)
    useEffect(() => {
        // Initially load data when the component mounts
        getCardData();
      }, []);
  return (
    <>
    <div className="breadcrumbarea">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb__content__wraper" data-aos="fade-up">
              <div className="breadcrumb__title">
                <h2 className="heading">Apply Batch</h2>
              </div>
              <div className="breadcrumb__inner">
                <ul>
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li>Apply Batch</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape__icon__2">
  
        <img
          className=" shape__icon__img shape__icon__img__2"
          src="/img/herobanner/herobanner__2.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__3"
          src="/img/herobanner/herobanner__3.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__4"
          src="/img/herobanner/herobanner__5.png"
          alt="photo"
        />
      </div>
    </div>
    {/* breadcrumbarea__section__end*/}
    {/* become__instructor__start */}

    
    { loading ? (
      <center> <br></br><br></br><div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div><br></br><br></br><br></br></center>
    ):(

<>
    
       {
      !type && !loading  ?(
    
    <div className="create__course sp_100">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12 col-12">
            <div className="create__course__accordion__wraper">
              <div className="accordion" id="accordionExample">
                <div className="become__instructor__form">
                  <Applyform type="batch" id={slug}  /> 
                    
                      

                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="course__details__sidebar--2">
              <div className="event__sidebar__wraper" data-aos="fade-up">

             


                <div
                  className="blogarae__img__2 course__details__img__2"
                  data-aos="fade-up"
                >
                   <img
                    
                    src={`https://manage.skillalfa.com/storage/batch/${Recentblog.Image}`}
                    alt="blog"
                  />
                  <div className="registerarea__content course__details__video">
                    <div className="registerarea__video">
                      <div className="video__pop__btn"></div>
                    </div>
                  </div>
                </div>
                <div className="event__price__wraper">
                  <div className="event__price">
                    ${Recentblog.DiscountedPrice} <del>/ ${Recentblog.ActualPrice}</del>
                  </div>
                  {/* <div className="event__Price__button">
                    <Link href="#">68% OFF</Link>
                  </div> */}
                </div>
                <div className="event__details__list">
                  <ul>
                    <li>
                      <div className="event__details__icon">
                        <i className="icofont-teacher" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Instrutor:</span>
                        <span>
                          <Link href="instructor-details.html">{Recentblog.Instrutor}</Link>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="event__details__icon">
                        <i className="icofont-clock-time" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Duration:</span>
                        <span>{Recentblog.Duration}</span>
                      </div>
                    </li>
                    <li>
                      <div className="event__details__icon">
                        <i className="icofont-bulb-alt" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Course:</span>
                        <span>{Recentblog.cn}</span>
                      </div>
                    </li>
                    <li>
                      <div className="event__details__icon">
                        <i className="icofont-document-folder" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Lesson:</span>
                        <span>{Recentblog.Duration}</span>
                      </div>
                    </li>
                    {/* <li>
                      <div className="event__details__icon">
                        <i className="icofont-learn" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Enrolled:</span>
                        <span>65 students</span>
                      </div>
                    </li> */}
                    {/* <li>
                      <div className="event__details__icon">
                        <i className="icofont-globe-alt" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Language:</span>{" "}
                        <span>English</span>
                      </div>
                    </li> */}
                    {/* <li>
                      <div className="event__details__icon">
                        <i className="icofont-certificate" />
                      </div>
                      <div className="event__details__date">
                        <span className="sb_label">Certificate:</span>
                        <span>Yes</span>
                      </div>
                    </li> */}
                  </ul>
                </div>
       
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ):(

    <center>
    <br></br> <br></br>
    <br></br> <br></br>
    <h2>No data available!</h2>
    <br></br>
    </center>
    
 
 )

}
</>
    )}


<Outlet/>
</>

  )
 
}
