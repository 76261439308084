'use client' 
import React, { useState, useEffect } from "react";
import Box from "./Box";

const Home = (props) => {
  const [card, setCard] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [HasData, setHasData] = useState(true);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [empty, setempty] = useState(false);


  const getCardData = async () => {
    if (!HasData || isFetching) {
      return;
    }

    setIsFetching(true);
    setLoading(true)
    try {
      const res = await fetch(
        `https://manage.skillalfa.com/api/v1/category/${props.slug}?page=${page}`
      );
      const data = await res.json();
      if (data.status) {
        setCard((prev) => [...prev, ...data.blog.data]);
        setPage((prev) => prev + 1);
       
      } else {
        setHasData(false);
        setempty(true)
       
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false)
      setIsFetching(false);
    }
  };

  const handleScroll = () => {
    if (!loading && HasData && !isFetching) {
      const windowHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - footerHeight;

      if (windowHeight + scrollTop + 10 >= scrollHeight) {
        getCardData();
      }
    }
  };

  // Debounce function to throttle the scroll event
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  // Use a debounce function to throttle the scroll event
  const debounceScroll = debounce(handleScroll, 200);

  useEffect(() => {
    const footer = document.getElementById("your-footer-id");
    if (footer) {
      const footerHeight = footer.offsetHeight;
      setFooterHeight(footerHeight);
    }

    window.addEventListener("scroll", debounceScroll);

    return () => {
      window.removeEventListener("scroll", debounceScroll);
    };
  }, [footerHeight, loading, HasData, isFetching]);

  useEffect(() => {
    // Initially load data when the component mounts
    getCardData();
  }, []);

  return (
    <>


     { empty &&  HasData &&
       
<center>
  <br></br> <br></br>
  <br></br> <br></br>
  <h2>No data available!</h2>
 
  </center>
     
     }
      <Box batchInfo={card} />

      {/* Render your footer here */}
      {/* <div id="your-footer-id">Your Footer Content</div> */}
      {loading && 
      <>
      <center> <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div></center>
    <br />
    </>
      }
    </>
  );
};

export default Home;
