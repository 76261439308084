import React from 'react'
import Quotepage from './Quotepage'
import { Link,Outlet } from "react-router-dom";

export default function Quote() {
  return (
    <>
    
    
    <div className="registerarea sp_top_90">
            <div className="container">
                <div className="row">
                    <div
                        className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12"
                        data-aos="fade-up"
                    >
                        <div className="registerarea__wraper">
                            <div className="section__title registerarea__section__title">
                                <div className="section__title__button">
                                   <Link  to="/courses" > <div className="default__small__button">
                                        Course List at SKILL ALFA INSTITUTE
                                    </div></Link>
                                </div>
                                <div className="section__title__heading heading__underline">
                                    <h3 style={{ color: "white" }}>
                                        Register Your Account and unlock a gateway to{" "}
                                        <small> comprehensive and industry-relevant </small> courses.
                                    </h3>
                                </div>
                            </div>
                            <div className="registerarea__content">
                                <div className="registerarea__video">
                                    <div className="video__pop__btn">
                                        <Link
                                            className="video-btn"
                                            to="https://www.youtube.com/watch?v=_jfUF4skwjo"
                                        >
                                            {" "}
                                            <img src="img/icon/video.png" alt="" />
                                        </Link>
                                    </div>
                                    <div className="registerarea__para">
                                        <p>
                                            Empower Yourself with New Skills &amp; Forge Your Career
                                            from Our Campus in Garden Town, Multan Cantt
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12"
                        data-aos="fade-up"
                    >
                       <Quotepage></Quotepage>

                    </div>
                </div>
            </div>
            <div className="registerarea__img">
                <img
                    className="register__1"
                    src="img/register/register__1.png"
                    alt="register"
                />
                <img
                    className="register__2"
                    src="img/register/register__2.png"
                    alt="register"
                />
                <img
                    className="register__3"
                    src="img/register/register__3.png"
                    alt="register"
                />
            </div>
        </div>
    </>
  )
}
