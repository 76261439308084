
import React from "react";
import Boxcomponent from "./Boxcomponent";

    const Box = ({ batchInfo }) => {
  return (
    <>
 <div className="coursearea sp_top_100 sp_bottom_100">
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div
            className="tab-content tab__content__wrapper with__sidebar__content"
            id="myTabContent"
          >
            <div
              className="tab-pane fade  active show"
              id="projects__one"
              role="tabpanel"
              aria-labelledby="projects__one"
            >
              <div className="row">
          
            
          {batchInfo.map((dataa, index) => {
        return <Boxcomponent key={index} myData={dataa} />;
          })} 
          </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
    
    </>
  );
};

export default Box;
