import React, { useState, useEffect } from "react";

export default function Newsletter() {
    const [email, setemail] = useState("");
    const [loader, setloader] = useState('Subscribe Now');
    const [ouput, setouput] = useState('');
    
     const handleemail =(e)=>{
        const inputValue = e.target.value;
        setemail(inputValue);
     }
    const formsubmit = async (e) => {
        e.preventDefault();
        setloader("Loading....")
        const formData = new FormData();
        formData.append("email", email);
        
    const resetFormFields = () => {
        setemail("");  
      };

      try {

        const response = await fetch('https://manage.skillalfa.com/api/v1/newsletter', {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          //console.log(data);
          if (data.status) {
            resetFormFields();
            setloader("Subscribe Now");
            setouput(data.msg)
          } else {
            setloader("Subscribe Now");
            setouput(data.msg)
  
          }
        } else {
          console.error('Error inserting data');
        }
      } catch (error) {
  
        console.error('Error:', error);
      }
      finally {
  
      }
  
  


    }

  return (
    <div>

         <div className="footerarea__newsletter">
                <div className="footerarea__newsletter__input">
                  <form onSubmit={formsubmit}>
                    <input type="email" value={email} onChange={handleemail} required placeholder="Enter your email here" />
                    <div className="footerarea__newsletter__button">
                      <button type="submit" className="subscribe__btn">
                        Subscribe Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="" dangerouslySetInnerHTML={{ __html: ouput }} />

 
    </div>
  )
}
