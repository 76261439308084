'use client' 
import React from "react";
import Boxcomponent from "./Boxcomponent";

    const Box = ({ batchInfo }) => {
  return (
    <>
  <div className="blogarea__2 sp_top_100 sp_bottom_100">
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row col-lg-12">

            
          {batchInfo.map((dataa, index) => {
        return <Boxcomponent key={index} myData={dataa} />;
          })} 
            
            </div>
        </div>
      </div>
    </div>
  </div>

    
    </>
  );
};

export default Box;
