import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";


export default function Policy() {
  
 
    useEffect(() => { 
 
        window.scrollTo(0, 0);
    
             })


  return (
    <div>

<>
  <div className="breadcrumbarea" data-aos="fade-up">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper">
            <div className="breadcrumb__title">
              <h2 className="heading">Privacy & Policy</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> Privacy & Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
     
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
  <br />
  <br />
  <br /><br /><br />
               <div className="container" >
     
    <div>
      <p>
        <b>1. Introduction<br></br></b>
        <div style={{ marginLeft: 20 }}  >Welcome to SKILL ALFA INSTITUT. We are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how SKILL ALFA INSTITUTE collects, uses, discloses, and safeguards your personal information when you use our website and services.
</div>
<br></br>
<b>2. Information We Collect<br></br></b>
        <div style={{ marginLeft: 20 }}  >
        <b>2.1 Personal Information: </b>SKILL ALFA INSTITUTE may collect personal information from you when you voluntarily provide it to us, such as when you register for SKILL ALFA INSTITUTE courses, contact SKILL ALFA INSTITUTE, or sign up for SKILL ALFA INSTITUTE's newsletter. This information may include your name, email address, phone number, and other contact details.

</div>
<div style={{ marginLeft: 20 }}  >
 <b>2.2 Payment Information:</b> When you make a payment for SKILL ALFA INSTITUTE courses, SKILL ALFA INSTITUTE collects payment information, including credit card or other payment method details. However, SKILL ALFA INSTITUTE does not store this payment information, and it is processed securely through SKILL ALFA INSTITUTE's payment service provider.

</div>

<div style={{ marginLeft: 20 }}  >
<b>2.3 Course Data: </b>SKILL ALFA INSTITUTE may collect data related to your course enrollment and progress, including assessments, assignments, and course-related interactions.

</div>
<div style={{ marginLeft: 20 }}  >
<b>2.4 Technical Information: </b>SKILL ALFA INSTITUTE may automatically collect technical information about your visit to SKILL ALFA INSTITUTE's website, such as your IP address, device information, browser type, and operating system.

</div>
<br></br>
<b>3. How We Use Your Information
<br></br></b>

<div style={{ marginLeft: 20 }}  >
<b>SKILL ALFA INSTITUTE may use your personal information for the following purposes</b>
</div>
<div style={{ marginLeft: 20 }}  >
<b>3.1 Course Enrollment and Administration: </b>To enroll you in SKILL ALFA INSTITUTE courses, provide course materials, communicate with you, and administer SKILL ALFA INSTITUTE's educational programs.
</div>
<div style={{ marginLeft: 20 }}  >
<b>3.2 Payment Processing:</b> To process payments for SKILL ALFA INSTITUTE course fees and related transactions.

</div>
<div style={{ marginLeft: 20 }}  >
<b>3.3 Communication: </b>To send you updates, newsletters, and promotional materials (you may opt out of these communications at any time).

</div>
<div style={{ marginLeft: 20 }}  >
<b>3.4 Improvement and Analytics:</b> To improve SKILL ALFA INSTITUTE courses, services, and website functionality and to conduct data analysis and research.

</div>
<div style={{ marginLeft: 20 }}  >
<b>3.5 Compliance:</b> To comply with legal and regulatory requirements.

</div>
<br></br>
<b>4. Sharing Your Information

<br></br></b>
<div style={{ marginLeft: 20 }}  >
  <b>SKILL ALFA INSTITUTE does not sell, trade, or rent your personal information to third parties. However, SKILL ALFA INSTITUTE may share your information with:</b>
</div>
<div style={{ marginLeft: 20 }}  >
<b>4.1 Service Providers: </b>SKILL ALFA INSTITUTE may engage third-party service providers to assist in providing SKILL ALFA INSTITUTE's services, such as payment processors, and these providers may have access to your personal information.

</div>
<div style={{ marginLeft: 20 }}  >
<b>4.2 Legal Requirements: </b>SKILL ALFA INSTITUTE may disclose your information in response to lawful requests, court orders, or legal processes.

</div>
<br></br>
<b>5. Data Security
<br></br></b>
<div style={{ marginLeft: 20 }}  >
SKILL ALFA INSTITUTE takes reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission or storage system can be guaranteed to be 100% secure.


</div>
<br></br>
<b>6. Changes to this Privacy Policy

<br></br></b>
<div style={{ marginLeft: 20 }}  >
SKILL ALFA INSTITUTE may update this Privacy Policy from time to time to reflect changes in SKILL ALFA INSTITUTE's practices and services. The updated policy will be posted on SKILL ALFA INSTITUTE's website with the date of the latest revision.

</div>


<br></br>
<b>7. Contact Us
<br></br></b>
<div style={{ marginLeft: 20 }}  >
If you have any questions or concerns about this Privacy Policy or SKILL ALFA INSTITUTE's data practices, please contact SKILL ALFA INSTITUTE at:<br></br>
SKILL ALFA INSTITUTE
1st Floor, MCB Bank,
Garden Town, Multan Cantt.
Phone: 061-44-86-86-0
Cell: 0335-44-86-86-0
Email: Ask@SkillAlfa.com

</div>


      </p>
    </div>
    
    <br />
               </div> 
               
         
 <>

</>
 
</>
<Outlet/>
    </div>
  )
}

<div style={{ marginLeft: 20 }}  >
</div>