import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
import Applyform from '../components/Applyformb'
import {Helmet} from "react-helmet";
import Quotepage from "../components/Quotepage";

export default function Accountmanagement() {

  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })
  return (
    <>
       <Helmet>
    <title>Account Management  || SKILL ALFA INSTITUTE </title>
        <meta name="description" content="Expertly manage your online business with us.

" />
    </Helmet>
  <div className="breadcrumbarea">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper" data-aos="fade-up">
            <div className="breadcrumb__title">
              <h2 className="heading">Account Management</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Account Management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
  {/* breadcrumbarea__section__end*/}
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <img
          style={{ width: "100%" }}
          src="/img/iStock-1439918276.png"
        />
      </div>
      <div className="col-lg-6">
        <br />
        <br />
        <br />
        <h5>Your Store, Our Expertise, Maximum Profits</h5>
        <p>
       SKILL ALFA INSTITUTE provides businessmen with a unique opportunity to excel in eCommerce. We offer a hassle-free path to success, allowing you to maintain complete control over your online store. You have the flexibility to update passwords anytime, ensuring your store's security. Our dedicated account management service ensures peak store performance, while our experienced team drives profits. Join Skill Alfa to skyrocket your eCommerce success – always ahead, always Alfa.
        </p>
      </div>
    </div>
  </div>
  {/* become__instructor__start */}
  <div className="create__course sp_100">
    <div className="container">
    
    <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                    <div className="create__course__accordion__wraper">
                        <div className="accordion" id="accordionExample">
                       <Quotepage></Quotepage>
<br></br><br></br><br></br><br></br><br></br>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="create__course__wraper">
                        <div className="create__course__title">
                            <h4>Maximize Profits</h4>
                        </div>
                        <div className="create__course__list">
                            <ul>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Control your online store effortlessly with Skill Alfa's expertise.
                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Empower yourself with full account control, including password updates
                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Experience peak store performance with our dedicated account management.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Drive profits with our experienced team by your side.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Elevate your eCommerce success - always ahead with Skill Alfa.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Hassle-free path to eCommerce excellence awaits you.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Join Skill Alfa and take charge of your online success.

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  </div>
  <Outlet/>
</>

  )
}
