import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
export default function Trendingcourse() {

    const [loading, setLoading] = useState(true);
  const [type, settype] = useState(false);
  const [Recentcourse, setRecentcourse] = useState([]);
    const getCardData = async () => {
    try {
        const res = await fetch(
          `https://manage.skillalfa.com/api/v1/homecourse`
        );
        const data = await res.json();
        console.log(data.footerblog)
        if (data.status) {
          setRecentcourse(data.homecourse)
          //console.log(card)
         
        } else {
          settype(true)
            console.log("no")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
        // Initially load data when the component mounts
        getCardData();
      }, []);




  return (
    <>
    
    <br></br>
    <br></br>
    <div className="col-xl-12" data-aos="fade-up">
      <div className="section__title__2 text-center teamarea__margin">
       
        <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
          <h2>Our Trending Courses</h2>
        </div>
      </div>
    </div>
    <br></br>
  <div style={{ overflowY:'hidden' }}  className="col-xl-12 col-lg-12 col-md-12 col-12 container">
    <div
      className="tab-content tab__content__wrapper with__sidebar__content"
      id="myTabContent"
    >
      <div
        className="tab-pane fade  active show"
        id="projects__one"
        role="tabpanel"
        aria-labelledby="projects__one"
      >
        <div className="row">
        {

loading && 

<center> <div className="spinner-border" role="status">
<span className="sr-only">Loading...</span>
</div></center>

}
{

!loading && 
<>
{Recentcourse.length > 0 ? (
    <>        {Recentcourse.map((dt, index) => (

          <div
                  className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-12"
                  data-aos="fade-up"
                >
                  <div className="gridarea__wraper gridarea__wraper__2">
                    <div className="gridarea__img">
                      
                        <img src={`https://manage.skillalfa.com/storage/course/${dt.Image}`} alt="grid" />
                      
                      <div className="gridarea__small__button">
                        {/* <div className="grid__badge">Data &amp; Tech</div> */}
                      </div>
                      <div className="gridarea__small__icon">
                        <a href="">
                          <i className="icofont-heart-alt" />
                        </a>
                      </div>
                    </div>
                    <div className="gridarea__content">
                      <div className="gridarea__list">
                        <ul>
                          <li>
                            <i className="icofont-book-alt" /> {dt.Totallessons}
                          </li>
                          <li>
                            <i className="icofont-clock-time" /> {dt.Duration}
                          </li>
                        </ul>
                      </div>
                      <div className="gridarea__heading">
                        <h3>
                          
                          {dt.Name}
                          
                        </h3>
                      </div>
                      <div style={{color:'#25a49d'}} className="gridarea__star">
                        Credits Hours: 
                          <span style={{color:'#25a49d'}}> {dt.CreditsHours}</span>
                        </div>
                      {/* <div className="gridarea__price"> */}
                      <b style={{ color:'rgb(37 164 157)'  }} >Course fees</b>   {dt.CoursePrice-dt.DiscountedPrice} PKR <del>({dt.CoursePrice} </del>PKR) 

                        {/* Rs {dt.CoursePrice-dt.DiscountedPrice}  */}
                        {/* <span> */}
                          {" "}
                          {/* <del className="del__2">Free</del> */}
                        {/* </span>
                        
                      </div> */}
                      
                      <div className="gridarea__bottom">
                       
                          <div className="gridarea__small__img">
                          {  dt.gender == "male" ? (

                            <img src="/img/grid/grid_small_1.jpg" alt="grid" />

                            ):(
<>
                            <img src="/img/grid/grid_small_2.jpg" alt="grid" />
</>
                            )}
                            <div className="gridarea__small__content">
                              <h6>{dt.Mentor}</h6>
                            </div>
                          </div>
                        
                        <div className="gridarea__details">
                    <Link to={`/courses/apply/${dt.id}`}>
                      Apply now
                      <i className="icofont-arrow-right" />
                    </Link>
                  </div>
                      </div>
                    </div>
                  </div>
                </div>


        ))}
          </>
        
        ) : (
            <div>
             
             <center><p>No Course  available!</p></center>
            </div>
          )}
          </>
        }
        
        </div>
      </div>
  
    </div>
  </div>
  <Outlet/>
    </>
  )
}
