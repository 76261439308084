'use client' 
import { Link,Outlet } from "react-router-dom";

import React from "react";


  const Boxcomponent = ({ myData }) => {
  var { title, id, image, author, name, meta_description,cn,slug ,created_at} = myData;

  //console.log(myData.ActualPrice)
// Input date string
var inputDateStr = created_at;

// Create a Date object from the input string
var inputDate = new Date(inputDateStr);

// Define an array of month names
var months = [
  "January", "February", "March",
  "April", "May", "June",
  "July", "August", "September",
  "October", "November", "December"
];

// Get the individual date components
var year = inputDate.getFullYear();
var month = inputDate.getMonth(); // 0-based index, so January is 0
var day = inputDate.getDate();
var hours = inputDate.getHours();
var minutes = inputDate.getMinutes();
var seconds = inputDate.getSeconds();

// Create a formatted date string
var formattedDate = months[month] + " " + day + ", " + year;
// var formattedDate = months[month] + " " + day + ", " + year + " " + hours + ":" + minutes + ":" + seconds;


  return (
    <>


<div className="col-lg-6">
              <div className="blog__content__wraper__2" data-aos="fade-up">
                <div className="blogarae__img__2">  
                  <img
                    style={{ height: 300 }}
                    src={`https://manage.skillalfa.com/storage/blog/${image}`}
                    alt="blog"
                  />
                  <div className="blogarea__date__2">
                    {formattedDate}
                  </div>
                </div>
                <div className="blogarea__text__wraper__2">
                  <div className="blogarea__heading__2">
                  <Link to={`/blog/${slug}`}>
                    <h3>
                   
                       {title}
                     
                    </h3>
                    </Link>
                  </div>
                  <div className="blogarea__list__2">
                    <ul>
                      <li>
                       
                          <i className="icofont-business-man-alt-2" /> {author}
                       
                      </li>

                      <li>
                       
                          <i className="icofont-eraser-alt" /> {cn}
                       
                      </li>
                    </ul>
                  </div>
                  <div className="blogarea__paragraph">
                    <p>
                      {meta_description}
                    </p>
                  </div>
                  <div className="blogarea__button__2">
                    <Link to={`/blog/${slug}`}>
                      READ MORE
                      <i className="icofont-double-right" />
                    </Link>
                    {/* <Link href="#">
                      <div className="blogarea__icon__2">
                        <i className="icofont-share" />
                        <i className="icofont-heart" />
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
<Outlet/>
    
    </>
  );
};

export default Boxcomponent;
