import React, { useState, useEffect } from "react";

export default function Applyform(props) {

  const [type, settype] = useState(props.type);
  const [type_id, settype_id] = useState(props.id);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [address, setaddress] = useState("");
  const [comment, setcomment] = useState("");
  const [qualification, setqualification] = useState("");
  const [gender, setgender] = useState("");
  const [loader, setloader] = useState('Submit');
  const [ouput, setouput] = useState('');
  const [ouputb, setouputb] = useState('');


  const handlequalification = (e) => {
    const inputValue = e.target.value;
    setqualification(inputValue);

  }
  const handlegender = (e) => {
    const inputValue = e.target.value;
    setgender(inputValue);

  }
  const handlename = (e) => {
    const inputValue = e.target.value;
    setname(inputValue);

  }
  const handleemail = (e) => {
    const inputValue = e.target.value;
    setemail(inputValue);

  }
  const handlephone = (e) => {
    const inputValue = e.target.value;
    setphone(inputValue);

  }
  const handleaddress = (e) => {
    const inputValue = e.target.value;
    setaddress(inputValue);

  }
  const handlecomment = (e) => {
    const inputValue = e.target.value;
    setcomment(inputValue);
  }


  const formsubmit = async (e) => {
    e.preventDefault();
    setloader("Loading....")
    const formData = new FormData();
    formData.append("type", type);
    formData.append("type_id", type_id);
    formData.append("qualification", qualification);
    formData.append("gender", gender);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("comment", comment);

    const resetFormFields = () => {
      setqualification("");
      setname("");
      setemail("");
      setphone("");
      setaddress("");
      setcomment("");
      setgender("");

    };


    try {

      const response = await fetch('https://manage.skillalfa.com/api/v1/apply', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.status) {
          resetFormFields();
          setloader("Submit");
          setouput(data.msg)
        } else {
          setloader("Submit");
          setouput(data.msg)

        }
      } else {
        console.error('Error inserting data');
      }
    } catch (error) {

      console.error('Error:', error);
    }
    finally {

    }


  }



  return (
    <form onSubmit={formsubmit} >
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#">Full Name</label>
            <input type="text" className="register__input" value={name} onChange={handlename} required style={{ backgroundColor: 'white' }} placeholder="Full Name" />
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#">Email</label>
            <input type="email" className="register__input" value={email} onChange={handleemail} required style={{ backgroundColor: 'white' }} placeholder="email" />
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#"> Mobile( WhatsApp ) </label>
            <input type="text" className="register__input" required style={{ backgroundColor: 'white' }} value={phone} onChange={handlephone} placeholder="Mobile( WhatsApp )" />
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#">Qualification</label>
            <input type="text" className="register__input" required style={{ backgroundColor: 'white' }} value={qualification} onChange={handlequalification} placeholder="Qualification" />
          </div>

        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#">Address</label>
            <input type="text" className="register__input" required style={{ backgroundColor: 'white' }} value={address} onChange={handleaddress} placeholder="Address" />
          </div>

        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">

          <div className="dashboard__form__input">
            <label htmlFor="#">Gender</label>
            <select className="register__input"
              style={{
                height: 50,
                width: "100%",
                border: "none",
                background: "var(--lightGrey8)",
                padding: "10px 15px",
                fontSize: 16,
                lineHeight: 54,
                marginBottom: 25,
                backgroundColor: 'white', border: 'none', outline: 'none', borderRadius: 6, border: "2px solid var(--borderColor)"
              }}

              required value={gender} onChange={handlegender} >
              <option value="" style={{ display: 'none' }} >select gender</option>
              <option>Male</option>
              <option> Female</option>
            </select>{" "}
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <br />

          <div className="dashboard__form__input">
            <label htmlFor="#">Comment box</label>
            <textarea className="register__input" style={{ backgroundColor: 'white', border: 'none', outline: 'none', border: "2px solid var(--borderColor)" }} value={comment} onChange={handlecomment}
              name=""
              id=""
              cols={30}
              rows={10}

            />
          </div>

        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <br />
          <br />
          <div className="dashboard__form__button create__course__margin">
            <button className="default__button" type="submit" >
              {loader}
              <i className="icofont-long-arrow-right" />
            </button>
           

            <div className="" dangerouslySetInnerHTML={{ __html: ouput }} />
             
             



          </div>
        </div>
      </div>
    </form>
  )
}
