function DynamicAccordion({ id, outline }) {
    return (
      <div
        style={{ padding: '0px', position: 'relative', top: '-35px', borderTopLeftRadius: '0px' }}
        className="accordion content__cirriculum__wrap"
        id={id}
      >
        <div style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${id}`}>
            <button
              style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px', backgroundColor: '#25a49d',color:'white' }}
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${id}`}
              aria-expanded="false"
              aria-controls={`collapse-${id}`}

            >
            Course  outline
            </button>
          </h2>
          <div
            id={`collapse-${id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading-${id}`}
            data-bs-parent={`#heading-${id}`}
          >
            <div className="accordion-body">
              <div className="" dangerouslySetInnerHTML={{ __html: outline }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default DynamicAccordion;
  