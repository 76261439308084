import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function About() {

  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })

  return (

    <div>

<>
<Helmet>
    <title>About us  || SKILL ALFA INSTITUTE </title>
        <meta name="description" content="Learn about our mission to empower through education." />
    </Helmet>
  <div className="breadcrumbarea" data-aos="fade-up">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper">
            <div className="breadcrumb__title">
              <h2 className="heading">About Us</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
     
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
  <br />
  <br />
  <div className="container">
    <h2 style={{ textAlign: "center" }}> Discover Skill Alfa</h2>
    <h5>About Us</h5>
    <p>
      Welcome to SKILL ALFA INSTITUTE, where we firmly believe in staying
      "Always Ahead, Always Alfa". In the ever-evolving digital landscape, we
      stand as a beacon of excellence, paving the way for aspiring individuals
      to step into the world of e-commerce and digital proficiency with
      confidence. Our institute is a powerhouse of knowledge, equipped with
      state-of-the-art facilities that foster learning and growth in an
      inclusive environment.
    </p>
    <h5>Why Choose Skill Alfa</h5>
    <p>
      At Skill Alfa, we offer more than just education; we provide an experience
      that transcends traditional learning paradigms. Our weekend classes are
      coupled with an incubator program that nurtures practical skills,
      fostering a seamless transition from classroom learning to real-world
      application. Here, every day is an opportunity to learn and grow amidst
      the best amenities, including high-speed internet and comfortable sitting
      arrangements, all designed to fuel your passion for learning.
    </p>
    
    <h5>Investors</h5>
    <p>
      Skill Alfa extends a warm welcome to investors keen on partnering with a
      dynamic and transparent venture. Our e-commerce account management program
      promises a mutually beneficial partnership, where we take the reins of
      account management, allowing for profit sharing without the hassles of
      direct investment. Rest assured, our financial dealings are clear,
      transparent, and confined to official channels, ensuring peace of mind and
      security for all parties involved.
    </p>
    <h5>Donations &amp; Scholarships</h5>
    <p>
      At the SKILL ALFA INSTITUTE, we believe in giving back to the community.
      Our donation program is structured to offer deserving students a chance to
      learn without the burden of financial constraints. We maintain a policy of
      absolute confidentiality, preserving the dignity and confidence of
      scholarship recipients. Our donors play a significant role in this
      endeavor, aiding us in fostering a generation of well-educated, skilled
      individuals ready to take on the world. Transparency and trust are at the
      core of our donation program, allowing donors to witness the direct impact
      of their contributions.
    </p>
  </div>
 <>
  {/* populer__section__start */}
  <div className="populerarea sp_top_80 sp_bottom_50">
    <div className="container">
      <div className="row populerarea__wraper">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="populerarea__heading heading__underline">
            <div className="default__small__button">Course List</div>
            <h2>
              Populer <span>Subjects</span>
            </h2>
          </div>
        </div>
        <div className="col-xl-8 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="populerarea__button">
            {/*  <a class="default__button" to="#">All Catagories
                          <i class="icofont-long-arrow-right"></i>
                      </a> */}
            <div className="populerarea__content">
              <p>
                Building connections between students and prosperous digital
                futures begins at SKILL ALFA INSTITUTE.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="single__service">
            <div className="service__img">
              <img
                className="svhhovr"
                src="/img/svg/user-group-accounts-svgrepo-com.svg"
              />
            </div>
            <div className="service__content">
              {/* <h3><a to="course-details.html">Business Studies</a></h3> */}
              <p>
                <b>Amazon &amp; eBay</b> Wholesale Business is a journey;
                commence yours with us.
              </p>
            </div>
            <div className="service__small__img">
              <svg
                className="icon__hover__img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                  stroke="#FFB31F"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="single__service">
            <div className="service__img">
              <img src="/img/svg/ecommerce-svgrepo-com.svg" />
            </div>
            <div className="service__content">
              <p>
                <b>Shopify E-commerce</b> Upgrade your digital marketing
                prowess.
              </p>
            </div>
            <div className="service__small__img">
              <svg
                className="icon__hover__img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                  stroke="#FFB31F"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="single__service">
            <div className="service__img">
              <img src="/img/svg/play-ad-svgrepo-com.svg" />
            </div>
            <div className="service__content">
              <p>
                <b>Graphic Design &amp; Video Editing </b>Showcase your
                creativity and technical skills.
              </p>
            </div>
            <div className="service__small__img">
              <svg
                className="icon__hover__img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                  stroke="#FFB31F"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
          <div className="single__service">
            <div className="service__img">
              <img src="/img/svg/image-pen-svgrepo-com.svg" />
            </div>
            <div className="service__content">
              <p>
                <b>Digital Skills </b> Incubator Where practical experience
                empowers your learning journey.
              </p>
            </div>
            <div className="service__small__img">
              <svg
                className="icon__hover__img"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                  stroke="#FFB31F"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* populer__section__end */}
</>
  <br />
  <br />
  <div className="brandarea__2">
    <div className="container">
      <div className="row">
        <div
          className="brandarea__wraper brandarea__wraper__2"
          data-aos="fade-up"
        >
          <div className="brandarea__img">
            <Link to="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/amazon.png"
                alt="brand"
              />
            </Link>
          </div>
          <div className="brandarea__img">
            <Link to="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/ebay.png"
                alt="brand"
              />
            </Link>
          </div>
          <div className="brandarea__img">
            <Link to="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/etsy.png"
                alt="brand"
              />
            </Link>
          </div>
          <div className="brandarea__img">
            <Link to="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/freelancer.png"
                alt="brand"
              />
            </Link>
          </div>
          <div className="brandarea__img">
            <Link to="#">
              <img
                style={{ width: 150, margin: "0px !important" }}
                src="/img/partnr/shopify.png"
                alt="brand"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
<Outlet/>
    </div>
  )
}
