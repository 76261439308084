import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Courses from './courses/Courses'
import ApplyCourses from './courses/apply/Apply'
import Accountmanagement from './pages/Accountmanagement'
import Donation from './pages/Donation'
import Blog from './blog/Blog'
import Blogdetail from './blogdetail/Blogdetail'
import Category from './category/Category'
import Upcoming from './batch/upcoming/Upcoming'
import Ongoing from './batch/ongoing/Ongoing'
import Previous from './batch/previous/Previous'
import Applybatch from './batch/apply/Applybatch'
import Policy from './pages/Policy'
import Conditions from './pages/Conditions'
import Ceomessage from "./pages/Ceomessage";

function App() {
  return (
    <BrowserRouter    >
    <Navbar />
    <Routes>

      <Route path="/" element={<Home />} /> 
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/term-conditions" element={<Conditions />} />
     <Route path="/about-us" element={<About />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/courses/apply/*" element={<ApplyCourses />} />
      <Route path="/account-management" element={<Accountmanagement />} />
      <Route path="/donation" element={<Donation />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/*" element={<Blogdetail />} />
      <Route path="/category/*" element={<Category />} />
      <Route path="/batch/upcoming/*" element={<Upcoming />} />
      <Route path="/batch/Ongoing/*" element={<Ongoing />} />
      <Route path="/batch/Previous/*" element={<Previous />} />
      <Route path="/batch/apply/*" element={<Applybatch />} />
      <Route path="/ceo-message" element={<Ceomessage />} />

      {/* <Route path="/blogs" element={<Blogs />} />
      <Route path="/request-quote" element={<Requestquote />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blog/*" element={<Blog />} />
      <Route path="/category/*" element={<Category />} /> */}
    </Routes>
    <Footer />
  </BrowserRouter>
  );
}

export default App;
