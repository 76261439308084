import { Link,Outlet } from "react-router-dom";


import React from "react";
import DynamicAccordion from "../../components/DynamicAccordion";
import DayOfWeek from "../../components/DayOfWeek";


  const Boxcomponent = ({ myData }) => {
  var { name, id, image, duration, day, fees, discount, mentor,gender,outline,time,description} = myData;

  //console.log(myData.ActualPrice)
  function timeFormatter(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');
  
    // Convert hours to a number
    let hoursNum = parseInt(hours, 10);
  
    // Determine whether it's AM or PM
    let period;
    if (hoursNum >= 0 && hoursNum < 12) {
      period = 'AM';
      if (hoursNum === 0) {
        hoursNum = 12;
      }
    } else {
      period = 'PM';
      if (hoursNum !== 12) {
        hoursNum -= 12;
      }
    }
  
    // Format the time in 12-hour format with leading zero if necessary
    const formattedHours = hoursNum.toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${period}`;
  }
  
  return (
    <>


<div 
              className="gridarea__wraper gridarea__wraper__2 gridarea__course__list" 
            //   data-aos="fade-up"
            >
              <div className="gridarea__img">
              
                <img style={{ width: '342px',
    height: '214px' }}  src={`https://manage.skillalfa.com/storage/course/${image}`} alt="grid" />
                
                
                <div className="gridarea__small__icon">
              
                    <i className="icofont-heart-alt" />
                 
                </div>
              </div>
              <div className="gridarea__content">
                <div className="gridarea__list">
                  <ul>
                    {/* <li>
                      <i className="icofont-book-alt" /> {Totallessons}
                    </li> */}
                    <li>
                      <i className="icofont-clock-time" /> {duration}
                    </li>
                    
                    <li><b><DayOfWeek  dayNumber={day} />,  {timeFormatter(time)}</b> </li>
                 

                   
                  </ul>
                </div>
              
                <div className="gridarea__heading">
                  <h3>
                   
                    {name}
                   
                  </h3>
                  
                </div>
               
                <b style={{ color:'rgb(37 164 157)'  }} >Course fees</b>   {fees-discount} PKR <del>({fees} </del>PKR) 
                <div className="" dangerouslySetInnerHTML={{ __html: description }} />
         
                <div className="gridarea__bottom">
                  <div className="gridarea__bottom__left">
                   
                      <div className="gridarea__small__img">
                      {  gender == "male" ? (

<img src="/img/grid/grid_small_1.jpg" alt="grid" />

):(
<>
<img src="/img/grid/grid_small_2.jpg" alt="grid" />
</>
)}

                        <div className="gridarea__small__content">
                          <h6>{mentor}</h6>
                        </div>
                       
                      </div>
                    {/* <div className="gridarea__star">
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <span>(44)</span>
                    </div> */}
                  </div>
                  {/* <div className="gridarea__details">
                    <Link to={`/batch/apply/${id}`}>
                      Apply now
                      <i className="icofont-arrow-right" />
                    </Link>
                  </div> */}

<div className="bdjfbd">
    <img src="/img/arrow.png" alt="Rotating Arrow" className="arrow" />
  </div>
                </div>
                
              </div>
             
              
            </div>
            <DynamicAccordion id={id} outline={outline}  /> 

            

    <Outlet/>
    </>
  );
};

export default Boxcomponent;
