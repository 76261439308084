import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function Navbar() {
  return (
    <>
      <div className="topbararea">
        <div style={{ paddingLeft: "10px", paddingRight: "20px" }} className="">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="topbar__left">
                <ul>
                  <Link target="_blank" to="https://wa.me/+923354486860">
                    <li>
                      <i
                        style={{ fontSize: "20px", color: "#25a49d" }}
                        class="fa fa-whatsapp"
                      ></i>
                      +92 3354486860
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="topbar__right">
                <div className="topbar__icon">
                  <i className="icofont-location-pin" />
                </div>
                <div className="topbar__text">
                  <Link
                    target="_blank"
                    to="https://maps.app.goo.gl/Y8zZEnoLBfohvPeM8"
                  >
                    <p>
                      SKILL ALFA INSTITUTE, 1st Floor MCB Bank, Garden Town,
                      Shershah Road, Multan Cantt
                    </p>
                  </Link>
                </div>
                <div className="topbar__list">
                  <ul>
                    <li>
                      <Link
                        target="_blank"
                        to="http://www.facebook.com/SkillAlfaInstitute"
                      >
                        <i className="icofont-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="http://www.instagram.com/SkillAlfaInstitute"
                      >
                        <i className="icofont-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.youtube.com/@SkillAlfaInstitute"
                      >
                        <i className="icofont-youtube-play" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://www.tiktok.com/@SkillAlfaInstitute"
                      >
                        <img style={{ width: "15px" }} src="/img/tiktok.svg" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header>
        <div className="headerarea headerarea__3 header__sticky header__area">
          <div className="container desktop__menu__wrapper">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6">
                <div className="headerarea__left">
                  <div className="headerarea__left__logo">
                    <Link to="/">
                      <img
                        style={{ width: "215px", height: "75px" }}
                        src="/img/nnnlogo.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 main_menu_wrap">
                <div className="headerarea__main__menu">
                  <nav>
                    <ul>
                      <li>
                        <Link className="headerarea__has__dropdown" to="/">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="/about-us"
                        >
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="/courses"
                        >
                          Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="/account-management"
                        >
                          Account Management
                        </Link>
                      </li>
                      {/* <li><Link to='' className="headerarea__has__dropdown" >Batch
                        <i className="icofont-rounded-down"></i>
                      </Link>
                        <ul className="headerarea__submenu">
                          <li><Link to="/batch/upcoming">Upcoming Batch</Link></li>
                          <li><Link to="/batch/ongoing">Ongoing Batch</Link></li>
                          <li><Link to="/batch/previous">Previous Batch</Link></li>

                        </ul>
                      </li> */}
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="/donation"
                        >
                          Donation
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="/ceo-message"
                        >
                          CEO's Message
                        </Link>
                      </li>
                      <li>
                        <Link className="headerarea__has__dropdown" to="/blog">
                          Blogs
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-6">
                <div className="registerarea__content">
                  <div className="registerarea__video">
                    <div className="video__pop__btn">
                      <Link
                        className="video-btn"
                        to="https://www.youtube.com/watch?v=JZ3HrlDLpyo"
                        target="_blank"
                      >
                        <img src="/img/icon/video.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mob_menu_wrapper">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="mobile-logo">
                  <Link className="logo__dark" to="#">
                    <img
                      style={{ width: "100%" }}
                      src="/img/Logo-alfasvg-cropped.svg"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-6">
                <div className="header-right-wrap">
                  <div className="mobile-off-canvas">
                    <Link className="mobile-aside-button" to="#">
                      <i className="icofont-navigation-menu" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Mobile Menu Start Here */}
      <div className="mobile-off-canvas-active">
        <a className="mobile-aside-close">
          <i className="icofont  icofont-close-line" />
        </a>
        <div className="header-mobile-aside-wrap">
          <div className="mobile-menu-wrap headerarea">
            <div className="mobile-navigation">
              <nav>
                <ul className="mobile-menu">
                  <li>
                    <Link className="headerarea__has__dropdown" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="headerarea__has__dropdown" to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="headerarea__has__dropdown" to="/courses">
                      Courses
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="headerarea__has__dropdown"
                      to="/account-management"
                    >
                      Account Management
                    </Link>
                  </li>

                  {/* <li><Link  className="headerarea__has__dropdown" to="/batch/upcoming">Upcoming Batch</Link></li>
                  <li><Link className="headerarea__has__dropdown" to="/batch/ongoing">Ongoing Batch</Link></li>
                  <li><Link className="headerarea__has__dropdown" to="/batch/previous">Previous Batch</Link></li> */}
                  <li>
                    <Link className="headerarea__has__dropdown" to="/donation">
                      Donation
                    </Link>
                  </li>
                  <li>
                    <Link className="headerarea__has__dropdown" to="/ceo-message" >
                         CEO's Message
                    </Link>
                  </li>
                  <li>
                    <Link className="headerarea__has__dropdown" to="/blog">
                      Blogs
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <Link target="_blank" to="https://wa.me/+923354486860">
            {" "}
            <li>
              <i
                style={{ fontSize: "20px", color: "#25a49d" }}
                class="fa fa-whatsapp"
              ></i>{" "}
              +92 3354486860
            </li>
          </Link>
          <div className="mobile-social-wrap">
            <div className="topbar__text">
              <Link
                target="_blank"
                to="https://maps.app.goo.gl/Y8zZEnoLBfohvPeM8"
              >
                <p style={{ fontSize: "15px" }}>
                  {" "}
                  <i
                    style={{ fontSize: "24px", color: "#25a49d" }}
                    className="icofont-location-pin"
                  />
                  SKILL ALFA INSTITUTE, 1st Floor MCB Bank, Garden Town,
                  Shershah Road, Multan Cantt
                </p>
              </Link>
            </div>
            <br></br>

            <Link
              target="_blank"
              to="http://www.facebook.com/SkillAlfaInstitute"
            >
              <i className="icofont-facebook" />
            </Link>

            <Link
              target="_blank"
              to="http://www.instagram.com/SkillAlfaInstitute"
            >
              <i className="icofont-instagram" />
            </Link>

            <Link
              target="_blank"
              to="https://www.youtube.com/@SkillAlfaInstitute"
            >
              <i className="icofont-youtube-play" />
            </Link>

            <Link
              target="_blank"
              to="https://www.tiktok.com/@SkillAlfaInstitute"
            >
              <img style={{ width: "15px" }} src="/img/tiktok2.svg" />
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile Menu end Here */}
      <Outlet />
    </>
  );
}
