import React, { useState, useEffect } from "react";
import Applyformb from '../components/Applyformb'
import { Link,Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";
import Quotepage from "../components/Quotepage";

export default function Donation() {

  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })

  return (
    <>
       <Helmet>
    <title>Donation || SKILL ALFA INSTITUTE </title>
        <meta name="description" content="Support education; sponsor a student's course fees today.



" />
    </Helmet>
  <div className="breadcrumbarea">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper" data-aos="fade-up">
            <div className="breadcrumb__title">
              <h2 className="heading">Donation</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Donation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
  {/* breadcrumbarea__section__end*/}
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <img
          style={{ width: "100%" }}
          src="/img/iStock-1188864563.png"
        />
      </div>
      <div className="col-lg-6">
        <br />
        <br />
        <br />
        <br />
        <br />
        
        <h5>Transform Lives with Skill Alfa's Donations</h5>
        <p>
        SKILL ALFA INSTITUTE extends an invitation to change lives through our Donation program. Your support sponsors deserving, financially challenged students, opening doors to education and opportunity. Our pledge to confidentiality guarantees privacy for students and donors alike. We provide transparent updates on sponsored students' progress. Join us in shaping a brighter future for those in need – always ahead, always Alfa.        </p>
      </div>
    </div>
  </div>
  {/* become__instructor__start */}
  <div className="create__course sp_100">
    <div className="container">
    
    <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                    <div className="create__course__accordion__wraper">
                        <div className="accordion" id="accordionExample">
                        <Quotepage></Quotepage>
<br></br><br></br><br></br><br></br>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                    <div className="create__course__wraper">
                        <div className="create__course__title">
                            <h4>Transform Lives</h4>
                        </div>
                        <div className="create__course__list">
                            <ul>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Sponsor deserving students for education and opportunity.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Ensure donor and student privacy with confidentiality.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Provide transparent updates on sponsored student progress.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Empower the future through education and generosity.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Shape a brighter future with Skill Alfa's donations.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Create a lasting impact with your support.

                                </li>
                                <li>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-check"
                                    >
                                        <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                    Join our philanthropic community – always ahead, always Alfa.

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  </div>
  <Outlet/>
</>

  )
}
