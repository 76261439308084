'use client'
import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
export default function Quotepage() {
    const [category, setcategory] = useState("");
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [address, setaddress] = useState("");
    const [comment, setcomment] = useState("");
    const [loader, setloader] = useState('Submit');
    const [ouput, setouput] = useState('');

    



    const handlecategory = (e) => {
        const inputValue = e.target.value;
        setcategory(inputValue);

    }
    const handlename = (e) => {
        const inputValue = e.target.value;
        setname(inputValue);

    }
    const handleemail = (e) => {
        const inputValue = e.target.value;
        setemail(inputValue);

    }
const handlephone = (e) => {
    let inputValue = e.target.value;

    // Remove all characters that are not numbers or the plus sign
    inputValue = inputValue.replace(/[^0-9+]/g, '');

    setphone(inputValue);
}
    const handleaddress = (e) => {
        const inputValue = e.target.value;
        setaddress(inputValue);

    }
    const handlecomment = (e) => {
        const inputValue = e.target.value;
        setcomment(inputValue);
    }

   
    
    const formsubmit = async (e) => {
        e.preventDefault();
        setloader("Loading....")
        const formData = new FormData();
        formData.append("category", category);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("address", address);
        formData.append("comment", comment);

        const resetFormFields = () => {
            setcategory("");
            setname("");
            setemail("");
            setphone("");
            setaddress("");
            setcomment("");
          
          };


        try {
           
            const response = await fetch('https://manage.skillalfa.com/api/v1/quote', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
              //  console.log(data);
                if (data.status) {
                    setouput(data.msg)
                    setloader("Submit"); // Clear the loader after a delay
                    resetFormFields();
                   
                } else {
               
                    
                    console.error('Error:', data.message);
                    setouput(data.msg)
                    setloader("Submit"); // Clear the loader after a delay
                }
            } else {
                console.error('Error inserting data');
            }
        } catch (error) {

            console.error('Error:', error);
        }
      
     

    
    }
    return (
        <>
       <div className="registerarea__form">
                            <div className="registerarea__form__heading">
                                <h4>Fill Your Registration</h4>
                            </div>
                            <form onSubmit={formsubmit} >
                            <label  >Applying as:</label>
                            <div style={{ display:"flex" }} >

  <div style={{ marginLeft:'0px',marginRight:'20px' }} >
    <input  style={{ marginLeft:'0px',marginRight:'5px' }}
      type="radio"
      id="Student"
      name="category"
      value="Student"
      checked={category === 'Student'}
      onChange={handlecategory}
      required
    />
    <label htmlFor="Student">Student</label>
  </div>
  <div style={{marginRight:'20px' }} >
    <input   style={{ marginLeft:'0px',marginRight:'5px' }}
      type="radio"
      id="Donor"
      name="category"
      value="Donor"
      checked={category === 'Donor'}
      onChange={handlecategory}
      required
    />
    <label htmlFor="Donor">Donor</label>
  </div>

  <div>
    <input  style={{ marginLeft:'0px',marginRight:'5px' }}
      type="radio"
      id="AccountManagement"
      name="category"
      value="AccountManagement"
      checked={category === 'AccountManagement'}
      onChange={handlecategory}
      required
    />
    <label htmlFor="AccountManagement">Account Management</label>
  </div>
</div>
<br></br>
                                <input required value={name} onChange={handlename}
                                    className="register__input"
                                    type="text"
                                    placeholder="SKILL ALFA INSTITUTE"
                                />
                                <div className="row">
                                    <div className="col-xl-6">
                                        <input required value={email} onChange={handleemail}
                                            className="register__input"
                                            type="email"
                                            placeholder="Ask@SkillAlfa.com"
                                        />
                                    </div>
                                    <div className="col-xl-6">
                                        <input required value={phone} onChange={handlephone}
                                            className="register__input"
                                            type="text" title="Write your number with country code"  pattern="^[+\d]+$"
                                            placeholder="+923354486860"
                                        />
                                    </div>
                                </div>
                                <input required value={address} onChange={handleaddress}
                                    className="register__input"
                                    type="text"
                                    placeholder="1st Floor, MCB Bank, Garden Town, Multan"
                                />
                                <textarea style={{ border: 'none', outline: 'none' }}  value={comment} onChange={handlecomment}
                                    className="register__input textarea"
                                    name="#"
                                    id="#"
                                    cols={30}
                                    rows={10} placeholder="I'm Interested For Amazon Course"
                                    defaultValue={"Comment"}
                                />
                                <div className="registerarea__button">
                                    <button className="default__button" type="submit" >
                                        {loader}
                                        <i className="icofont-long-arrow-right" />
                                    </button>
                                    <div className="" dangerouslySetInnerHTML={{ __html: ouput }} />

                                   
                                </div>
                            </form>
                        </div>

        <Outlet/>
        </>
    )
}
