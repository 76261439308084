import React from 'react'

export default function Mntr() {
  return (
    <div className="teamarea sp_bottom_100 sp_top_50">
    <div className="container">
      <div className="row">
        <div className="col-xl-12" data-aos="fade-up">
          <div className="section__title__2 text-center teamarea__margin">
            <div className="section__small__title">
              <span>EXPERIENCED MENTORS</span>
            </div>
            <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
              <p>
                At SKILL ALFA INSTITUTE, our seasoned mentors are at the
                forefront of the digital landscape, imparting knowledge and
                skills with a hands-on approach. Not just teachers, they are
                industry experts committed to nurturing Alfa leaders of
                tomorrow, guiding them towards a prosperous career in the
                ever-evolving digital and e-commerce sectors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
          data-aos="fade-up"
        >
          <div className="single__team">
            <div className="teamarea__img">
              <img src="/img/3.png" alt="team" />
           
            </div>
            <div className="teamarea__content">
              <p>Amazon Expert</p>
              <h5>
                <a href="#">Maha Shahid</a>
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
          data-aos="fade-up"
        >
          <div className="single__team">
            <div className="teamarea__img">
              <img src="/img/2.png" alt="team" />
             
            </div>
            <div className="teamarea__content">
              <p>Shopify & Ebay Expert </p>
              <h5>
                <a href="#">Rizwan Saeed </a>
              </h5>
            </div>
          </div>
        </div>
        <div
          className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
          data-aos="fade-up"
        >
          <div className="single__team">
            <div className="teamarea__img">
              <img src="/img/1.png" alt="team" />
             
            </div>
            <div className="teamarea__content">
              <p>Graphic Design Expert
</p>
              <h5>
                <a href="#">Umar Farooq</a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
