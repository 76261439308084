
import { Link,Outlet } from "react-router-dom";

import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

 const Content = (props) => {
 // console.log(props.slug)
  const [card, setCard] = useState();
  const [tags, settags] = useState();
  const [loading, setLoading] = useState(true);
  const [category, setcategory] = useState([]);
  const [wrong, setwrong] = useState(false);
  const [Recentblog, setRecentblog] = useState([]);
    const getCardData = async () => {
    try {
        const res = await fetch(
          `https://manage.skillalfa.com/api/v1/blogdetail/${props.slug}`
        );
        const data = await res.json();
        console.log(data)
        if (data.status) {
          setCard(data.blog);
          settags(data.tags)
          setcategory(data.category)
          setRecentblog(data.Recentblog)
          //console.log(card)
         
        } else {
            setwrong(true)
            console.log("no")
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false)
      }
    }
    useEffect(() => {
        // Initially load data when the component mounts
        getCardData();
      }, []);
   // console.log(card.id)
   function formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();
  
    return `${day} ${months[month]} ${year}`;
  }
  return (
   <>

   {loading ? (
    <>
    <br />   <br />
       <center><br /> <div className="spinner-border" role="status">
       <span className="sr-only">Loading...</span>
     </div></center>
     <br />   <br />
     </>
    ) : wrong ? (
      <div>Failed to fetch data</div>
    ) : (
      
      <>
       {card ? (
        <>
              <Helmet>
    <title>{card.meta_title}  || SKILLA ALFA INSTITUTE </title>
     <meta name="description" content={card.meta_description} />
     <meta name="keyword" content={card.meta_tags} />
    </Helmet>
   <div className="blogarea__2 sp_top_100 sp_bottom_100">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="blog__details__content__wraper">
              <div className="blog__details__img" data-aos="fade-up">
                <img src={`https://manage.skillalfa.com/storage/blog/${card.image}`} alt="blog" />
               
              </div>
              <div className="blog__details__content">
              <h1>{card.title}</h1>
              <div className="" dangerouslySetInnerHTML={{ __html: card.content }} />
               </div>
              <div className="blog__details__tag" data-aos="fade-up">
                <ul>
                  <li className="heading__tag">Tags </li>
                  {tags.map((dt, index) => (
                  <li key={index} >
                     <li>
                    <Link to="">{dt.name}</Link>
                  </li>
                  </li>
                  ))}
                 
                 
                </ul>
              
              </div>
            </div>
          </div>
          <div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div
            
              className="blogsidebar__content__wraper__2"
              data-aos="fade-up"
            >
              <h4 className="sidebar__title">categories</h4>
              <ul className="categorie__list">

              {category.map((dt, index) => (
                <li>
                  <Link to={`/category/${dt.slug}`}>
                    {dt.name} 
                  </Link>
                </li>
                ))}
              </ul>
            </div>
            <div   style={{ position: "sticky", top: 150 }} className="blogsidebar__content__wraper__2" data-aos="fade-up">
              <h4 className="sidebar__title">Recent Post</h4>
              <ul className="recent__list">

              {Recentblog.map((dt, index) => (
                
                <li>
                  <div className="recent__img">
                    <Link to={`/blog/${dt.slug}`}>
                    <img src={`https://manage.skillalfa.com/storage/blog/${dt.image}`} alt="blog" />
                      <div className="recent__number">
                        <span>{index + 1}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="recent__text">
                    <div className="recent__date">
                      <Link to={`/blog/${dt.slug}`}>{formatDate(dt.created_at)}</Link>
                    </div>
                    <h6>
                      <Link  to={`/blog/${dt.slug}`}>{dt.title}</Link>
                    </h6>
                  </div>
                </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
) : (
<>  <center><br /><br /><p>No data available!</p><br /><br /></center>
 </>
)}
    </>
    )}

<Outlet/>
   </>
   
  )
}
export default  Content;