'use client' 
import React from "react";
import Boxcomponent from "./Boxcomponent";

    const Box = ({ batchInfo }) => {
  return (
    <>
<div className="coursearea sp_top_100 sp_bottom_100">
  <div className="container">
    <div className="row">
      <div className="col-xl-12">
        <div className="tab-content tab__content__wrapper" id="myTabContent">
          <div
            className="tab-pane fade active show"
            id="projects__two"
            role="tabpanel"
            aria-labelledby="projects__two"
          >
          
            
          {batchInfo.map((dataa, index) => {
        return <Boxcomponent key={index} myData={dataa} />;
          })} 
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    
    </>
  );
};

export default Box;
