import React from 'react'
import Home from './components/Home';
import { useParams,useNavigate  } from 'react-router-dom';
import { Link,Outlet } from "react-router-dom";




const Category = () => {
  const { '*': remainingPath } = useParams();
  const parts = remainingPath.split('/');
  const slug = parts[0];
  return (
    <>
    <div className="breadcrumbarea">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb__content__wraper" data-aos="fade-up">
              <div   className="breadcrumb__title">
                <h2 className="heading">Category</h2>
              </div>
              <div className="breadcrumb__inner">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>category</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape__icon__2">
   
        <img
          className=" shape__icon__img shape__icon__img__2"
          src="/img/herobanner/herobanner__2.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__3"
          src="/img/herobanner/herobanner__3.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__4"
          src="/img/herobanner/herobanner__5.png"
          alt="photo"
        />
      </div>
    </div>
  
    <Home slug={slug} ></Home>
    <Outlet/>
  </>
  
  )
}
export default Category;