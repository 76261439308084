import React from 'react'
import { Link,Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Ceomessage() {
  return (
    <>
      <Helmet>
        <title>CEO's Message || SKILL ALFA INSTITUTE </title>
        <meta name="description" content="Support education; sponsor a student's course fees today." />
     </Helmet>
     <div className="breadcrumbarea">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb__content__wraper" data-aos="fade-up">
                <div className="breadcrumb__title">
                  <h2 className="heading">CEO's Message</h2>
                </div>
                <div className="breadcrumb__inner">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>CEO's Message</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape__icon__2">
          <img
            className=" shape__icon__img shape__icon__img__2"
            src="/img/herobanner/herobanner__2.png"
            alt="photo"
          />
          <img
            className=" shape__icon__img shape__icon__img__3"
            src="/img/herobanner/herobanner__3.png"
            alt="photo"
          />
          <img
            className=" shape__icon__img shape__icon__img__4"
            src="/img/herobanner/herobanner__5.png"
            alt="photo"
          />
        </div>
      </div>
      <br></br>
      <div className="container">
        <div className="row">
        <div className="col-lg-6">
            <br />
            <br />
            <p>
                Welcome to <b>SKILL ALFA INSTITUTE</b>, a beacon of innovative education led by Jawwad Sadiq, an engineer and entrepreneur deeply rooted in the IT sector. Our institute, named for the ALFA generation, represents the apex of skill and innovation in today's world.
                At SKILL ALFA INSTITUTE, we address the gap in traditional education: the overemphasis on theory over practical skills. With 80% of students trapped in theoretical learning, we offer a unique solution: <b>a curriculum with 10% theory and 90% practical experience.</b> This approach transforms students into real-world practitioners, equipped to excel professionally.
            </p>
          </div>
          <div className="col-lg-6">
               <img style={{ width: "100%" }} src="/img/ahad-a.jpg" />
          </div>
          
        </div>
      </div>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
               <img style={{ width: "100%" }} src="/img/ahad-b.png" />
          </div>
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <p>
                Our advanced incubator system epitomizes this philosophy. Sundays focus on theoretical concepts, setting the stage for intensive, practical immersion from Monday to Friday. This ensures a well-rounded understanding and application of skills.
                Beyond education, our mission is to empower Pakistan's youth to attain global success without leaving their homeland.
                <br></br> Our motto:<br></br> 
                <b>"Don't leave your homeland, my friend.
                Earn dollar here, where visions extend."</b>
            </p>
            <center><img style={{ width:'290px' }} src="/img/shn.png" ></img></center>
          </div>
        </div>
      </div>
      <br></br>
    </>
  )
}
