import React, { useState, useEffect } from "react";
import Content from './Content'
import { useParams,useNavigate  } from 'react-router-dom';
import { Link,Outlet } from "react-router-dom";






const Blogdetail = () => {

  useEffect(() => { 
 
    window.scrollTo(0, 0);

         })
         
  const { '*': remainingPath } = useParams();
  const parts = remainingPath.split('/');
  const slug = parts[0];

  return (
    <>
  
    <div className="breadcrumbarea">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb__content__wraper" data-aos="fade-up">
              <div   className="breadcrumb__title">
                <h2 className="heading">

                Blog Detail
           
                </h2>
              </div>
              <div className="breadcrumb__inner">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Blog </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape__icon__2">
        {/* <img
          className=" shape__icon__img shape__icon__img__1"
          src="/img/herobanner/herobanner__1.png"
          alt="photo"
        /> */}
        <img
          className=" shape__icon__img shape__icon__img__2"
          src="/img/herobanner/herobanner__2.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__3"
          src="/img/herobanner/herobanner__3.png"
          alt="photo"
        />
        <img
          className=" shape__icon__img shape__icon__img__4"
          src="/img/herobanner/herobanner__5.png"
          alt="photo"
        />
      </div>
    </div>
    {/* breadcrumbarea__section__end*/}
    <Content slug={slug} ></Content>
    <Outlet></Outlet>
  </>
  
  )
}
export default Blogdetail;