import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";




export default function Policy() {
    useEffect(() => { 
 
        window.scrollTo(0, 0);
    
             })
  return (
    <div>

<>
  <div className="breadcrumbarea" data-aos="fade-up">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="breadcrumb__content__wraper">
            <div className="breadcrumb__title">
              <h2 className="heading">Term & Conditions</h2>
            </div>
            <div className="breadcrumb__inner">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> Term & Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape__icon__2">
     
      <img
        className=" shape__icon__img shape__icon__img__2"
        src="/img/herobanner/herobanner__2.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__3"
        src="/img/herobanner/herobanner__3.png"
        alt="photo"
      />
      <img
        className=" shape__icon__img shape__icon__img__4"
        src="/img/herobanner/herobanner__5.png"
        alt="photo"
      />
    </div>
  </div>
  <br />
  <br />
  <br /><br /><br />
               <div className="container" >
     
    <div>
      <p>
        <b>1. Introduction<br></br></b>
        <div style={{ marginLeft: 20 }}  >Welcome to Skill Alfa Institute! These terms and conditions govern your use of our website and services. By accessing or using our website, enrolling in our courses, or participating in any of our programs, you agree to comply with and be bound by these terms and conditions.</div>
<br></br>
<b>2. Course Enrollment<br></br></b>
        <div style={{ marginLeft: 20 }}  >
        <b>  2.1 Eligibility: </b>To enroll in our courses, you must meet the eligibility criteria specified on our website.
</div>
<div style={{ marginLeft: 20 }}  >
<b>2.2 Registration: </b>You agree to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials.
</div>

<div style={{ marginLeft: 20 }}  >
<b>2.3 Fees: </b>Course fees are as specified on our website. We reserve the right to change course fees at any time.
</div>
<div style={{ marginLeft: 20 }}  >

<b>2.4 Payment: </b>Payment for courses must be made in full before the course start date. We accept payment through the methods specified on our website.
</div>
<div style={{ marginLeft: 20 }}  >
<b>2.5 Refunds: </b>Refund policies are outlined on our website and may vary by course. Please review the specific refund policy for your enrolled course.

</div>
<br></br>



<b>3. Course Content
<br></br></b>

<div style={{ marginLeft: 20 }}  >
<b>3.1 Ownership:</b> All course materials, including but not limited to lectures, presentations, and course notes, are the intellectual property of SKILL ALFA INSTITUTE and may not be reproduced or distributed without our written consent.
</div>
<div style={{ marginLeft: 20 }}  >
<b>3.2 Access: </b> You will have access to course materials for the duration of the course and, in some cases, beyond. We reserve the right to revoke access in the event of a violation of these terms.</div>

<br></br>
<b>4. Privacy Policy
<br></br></b>
<div style={{ marginLeft: 20 }}  >
Please refer to our Privacy Policy, available on our website, for information about how we collect, use, and protect your personal information.
</div>
<br></br>
<b>5. Code of Conduct

<br></br></b>
<div style={{ marginLeft: 20 }}  >
You agree to adhere to a code of conduct that promotes a positive learning environment and prohibits any form of harassment, discrimination, or disruptive behavior. We reserve the right to remove any participant who violates this code of conduct.

</div>
<br></br>
<b>6. Disclaimers

<br></br></b>
<div style={{ marginLeft: 20 }}  >
<b>6.1 Warranty: </b>We do not make any warranties or representations regarding the accuracy or completeness of course content. Courses are provided on an "as-is" basis.

</div>
<div style={{ marginLeft: 20 }}  >
<b>6.2 Liability:</b> SKILL ALFA INSTITUTE shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of our website or participation in our courses.
</div>

<br></br>
<b>7. Modifications

<br></br></b>
<div style={{ marginLeft: 20 }}  >
We reserve the right to modify these terms and conditions at any time. Updated terms will be posted on our website, and your continued use of our services will constitute acceptance of the modified terms.
</div>


      </p>
    </div>
    
    <br />
               </div> 
               
         
 <>

</>
 
</>
<Outlet/>
    </div>
  )
}

<div style={{ marginLeft: 20 }}  >
</div>