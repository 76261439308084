
import { Link,Outlet } from "react-router-dom";
import React from "react";


  const Boxcomponent = ({ myData }) => {
  var { Name, id, Image, Duration, Totallessons, ActualPrice, DiscountedPrice, Instrutor,description,gender } = myData;



  return (
    <>


<div 
              className="gridarea__wraper gridarea__wraper__2 gridarea__course__list" 
            //   data-aos="fade-up"
            >
              <div className="gridarea__img">
               
                <img  src={`https://manage.skillalfa.com/storage/batch/${Image}`} alt="grid" />
                
                
                <div className="gridarea__small__icon">
           
                    <i className="icofont-heart-alt" />
                
                </div>
              </div>
              <div className="gridarea__content">
                <div className="gridarea__list">
                  <ul>
                    <li>
                      <i className="icofont-book-alt" /> {Totallessons}
                    </li>
                    <li>
                      <i className="icofont-clock-time" /> {Duration}
                    </li>
                  </ul>
                </div>
              
                <div className="gridarea__heading">
                  <h3>
                   
                    {Name}
                    
                  </h3>
                </div>
                <div className="gridarea__price">
                  $ {DiscountedPrice} <del>/ $ {ActualPrice}</del>
                  {/* <span>Free.</span> */}
                </div>
                <p>{description}</p>
                <div className="gridarea__bottom">
                  <div className="gridarea__bottom__left">
                   
                      <div className="gridarea__small__img">
                      {  gender == "male" ? (

<img src="/img/grid/grid_small_1.jpg" alt="grid" />

):(
<>
<img src="/img/grid/grid_small_2.jpg" alt="grid" />
</>
)}
                        <div className="gridarea__small__content">
                          <h6>{Instrutor}</h6>
                        </div>
                      </div>
                    
                    {/* <div className="gridarea__star">
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <i className="icofont-star" />
                      <span>(44)</span>
                    </div> */}
                  </div>
                  <div className="gridarea__details">
                    <Link to={`/batch/apply/${id}`}>
                      Apply now
                      <i className="icofont-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

    
    </>
  );
};

export default Boxcomponent;
